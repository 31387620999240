<template>
  <div class="parsing">
    <!-- <div class="img1">
      <img
        style="object-fit: cover"
        :src="content.productStyle.img1"
        width="100%"
        height="880px"
        alt=""
      />
    </div>
    <div class="img2">
      <img
        style="object-fit: cover"
        :src="content.productStyle.img2"
        width="100%"
        height="326px"
        alt=""
      />
    </div> -->
    <div class="info" style="margin-top: 100px">
      <div class="title" style="font-size: 50px">图形工作站</div>
    </div>
    <div
      class="img3"
      style="display: flex; margin-top: 60px; justify-content: center"
    >
      <img style="" src="../../assets/xiaofang.jpg" width="1100px" alt="" />
    </div>
    <!-- <div class="icon-list">
      <div
        class="icon-item"
        v-for="(item, index) in content.productStyle.icons"
        :key="index"
      >
        <div><img :src="item.icon" alt="" width="64px" height="64px" /></div>
        <div class="title">{{ item.title }}</div>
      </div>
    </div> -->
    <!-- <div class="info">
      <div class="title">{{ content.productStyle.content1.title }}</div>
      <div class="text" style="text-align: center">
        {{ content.productStyle.content1.description }}
      </div>
      <div class="url-box">
        <img
          :src="content.productStyle.content1.resourceUrl"
          width="1200px"
          height="600px"
          alt=""
        />
      </div>
    </div> -->
    <!-- <div class="info">
      <div class="title">{{ content.productStyle.content2.title }}</div>
      <div class="text" style="text-align: center">
        {{ content.productStyle.content2.description }}
      </div>
      <div class="url-box">
        <div class="url-box" v-if="content.productStyle.content2.resourceUrl">
          <video
            autoplay="autoplay"
            loop="loop"
            muted
            width="1200px"
            height="600px"
          >
            <source
              :src="content.productStyle.content2.resourceUrl"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div> -->
    <!-- <div class="info">
      <div class="title">{{ content.productStyle.content3.title }}</div>
      <div class="text">{{ content.productStyle.content3.description }}</div>
      <div class="url-box">
        <img
          :src="content.productStyle.content3.resourceUrl"
          width="1200px"
          height="510px"
          alt=""
        />
      </div>
    </div> -->
    <div class="parameter">
      <div class="title">技术规格</div>
      <div class="url-box">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="name">{{ item.name }}</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="title">融合应用</div>
      <ol>
        <li>
          <span class="name">消防应急：</span
          >应急队伍信息管理、应急任务调度与记录、应急资源基础信息管理
        </li>
        <li>
          <span class="name">消防日常：</span
          >消防巡查、消防研判分析处置、事件统计表
        </li>
        <li>
          <span class="name">设备管理：</span
          >设备连接、设备配置、设备监控、设备数据采集、告警设置
        </li>
        <li>
          <span class="name">系统基础管理：</span
          >通知配置、历史报警、事件流程配置；用户流程自定义
        </li>
        <li style="display: flex">
          <span class="name">调度可视化：</span
          >3D可视化操作，地图、火灾风险监测、智能消防视频分析，直观展示消防设备、事件、预警等信息的地理位置和周边环境
        </li>
      </ol>
    </div>
    <!--        <look-demo></look-demo>-->
  </div>
</template>

<script>
// import lookDemo from "./model-look";
export default {
  name: "parsing",
  //   components: { lookDemo },
  data() {
    return {
      content: null,
      list: [
        {
          name: "产品型号",
          value: "AR-RHSJ002",
        },
        {
          name: "产品型态",
          value: "2U机架式服务器",
        },
        {
          name: "处理器",
          value: "配置内核数≥32 核，处理器基本频率≥2.20 GHZ，缓存≥60 MB",
        },
        {
          name: "内存",
          value: "最大支持512G DDR5 4800内存，32 个 DDR5 内存插槽",
        },
        {
          name: "硬盘",
          value: "最大支持50个2.5寸硬盘和40个NVME硬盘",
        },
        {
          name: "网卡",
          value: "2个千兆网卡",
        },
        {
          name: "终端散热",
          value: "支持 6 个热插拔风扇模组，支持 N+2 冗余",
        },
      ],
    };
  },
  watch: {
    "$route.query.id"() {
      this.getDetail();
    },
  },
  created() {
    // this.getDetail();
  },
  methods: {
    getDetail() {
      this.content = null;
      this.$get("/website/product/style4/" + this.$route.query.id)
        .then((res) => {
          this.content = res.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss">
.parsing {
  .img1 {
    width: 100%;
    height: 880px;
  }
  .icon-list {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-item {
      width: 25%;
      text-align: center;
      margin-top: 30px;
    }
    .icon-item:nth-child(4n) {
    }
  }
  .info,
  .parameter {
    width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 34px;
      text-align: center;
      margin-top: 128px;
    }
    .text {
      margin: 0 auto;
      margin-top: 20px;
      width: 800px;
    }
    .url-box {
      margin-top: 30px;
      .list-item {
        padding-left: 20px;
        height: 40px;
        display: flex;
        font-size: 20px;
        color: #565656;
        border-bottom: 1px solid #b6b6b6;
        margin-top: 12px;
        .name {
          width: 90px;
          text-align: justify;
          text-align-last: justify;
          margin-right: 110px;
        }
      }
    }
    ol {
      margin-top: 30px;
      li {
        font-size: 20px;
        color: #565656;
        // height: 40px;
        margin-bottom: 10px;
        line-height: 30px;
        list-style: none;
        margin-left: -20px;
        .name {
          display: inline-block;
          width: 140px;
          //   text-align: justify;
          //   text-align-last: justify;
        }
      }
    }
  }
}
</style>
